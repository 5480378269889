import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import ImageHeader from "components/ui/base/ImageHeader";
import { useTranslation } from "react-i18next";
import CustomerSatisfactionFeatures from "../../components/ui/extended/CustomerSatisfactionFeatures";
import NumberSteps from "components/ui/base/NumberSteps";
import FullWidthImage from "components/ui/base/FullWidthImage";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";

interface CustomerSatisfactionPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: CustomerSatisfactionPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.solutions.customer-satisfaction.seo.title")}
        description={t("pages.solutions.customer-satisfaction.seo.description")}
        image={{
          relativePath: "meta/customer-satisfaction-software.jpg",
          alt: "Customer satisfaction Software to make your customers happier",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.solutions.customer-satisfaction.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t(
              "pages.solutions.customer-satisfaction.seo.description"
            ),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.solutions.customer-satisfaction.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.solutions.customer-satisfaction.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />
      <ImageHeader
        title={t("pages.solutions.customer-satisfaction.title")}
        description={t("pages.solutions.customer-satisfaction.description")}
        cta={{ show: true }}
        image={{
          alt: t("pages.solutions.customer-satisfaction.title"),
          relativePath:
            "pages/solutions/customer-satisfaction/customer-satisfaction-feedback.jpg",
        }}
      />

      <NumberSteps
        title={t("pages.solutions.customer-satisfaction.steps.title")}
        elements={Array.from(Array(3).keys()).map((i) => {
          return {
            title: t(
              `pages.solutions.customer-satisfaction.steps.${i + 1}.title`
            ),
            description: t(
              `pages.solutions.customer-satisfaction.steps.${i + 1}.description`
            ),
          };
        })}
      />

      <FullWidthImage
        title={t("pages.solutions.customer-satisfaction.nps.title")}
        description={t("pages.solutions.customer-satisfaction.nps.description")}
        cta={{
          text: t("pages.solutions.customer-satisfaction.nps.cta"),
          link: "/features/net-promoter-score/",
        }}
        image={{
          relativePath:
            "pages/solutions/customer-satisfaction/customer-satisfaction-nps-survey.jpg",
          alt: t("pages.solutions.customer-satisfaction.nps.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.solutions.customer-satisfaction.csat.title")}
        description={t(
          "pages.solutions.customer-satisfaction.csat.description"
        )}
        cta={{
          text: t("pages.solutions.customer-satisfaction.csat.cta"),
          link: "/features/surveys/",
        }}
        image={{
          relativePath:
            "pages/solutions/customer-satisfaction/customer-satisfaction-in-chat-survey.jpg",
          alt: t("pages.solutions.customer-satisfaction.csat.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.solutions.customer-satisfaction.funnels.title")}
        description={t(
          "pages.solutions.customer-satisfaction.funnels.description"
        )}
        cta={{
          text: t("pages.solutions.customer-satisfaction.funnels.cta"),
          link: "/features/marketing-funnel/",
        }}
        image={{
          relativePath:
            "pages/solutions/customer-satisfaction/customer-satisfaction-software.jpg",
          alt: t("pages.solutions.customer-satisfaction.funnels.title"),
          position: "left",
          fullWidth: true,
        }}
      />
      <CustomerSatisfactionFeatures
        customTitle={t("pages.solutions.customer-satisfaction.featuresTitle")}
      />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const CustomerSatisfactionQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customer-satisfaction-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
